<template>
  <div id="content" class="accordion kc-section">
    <div class="container">
      <h2 class="text-center mb-5">
        {{ $t('programme.title') }}
        <img class="h2-logo" :src="require('@/assets/logo-horizontal-light.png')" alt="logo-komenskeho-college" />
      </h2>
      <div class="row">
        <div class="col-lg-5">
          <div id="accordionOne">
            <div v-for="(item, i) of accordionItems" :key="i" class="item">
              <div :id="`heading${i}`">
                <span
                  data-toggle="collapse"
                  :data-target="`#collapseOne${i}`"
                  :aria-expanded="i == 0"
                  :aria-controls="`collapseOne${i}`"
                  role="button"
                >
                  <span class="circle-numbering">{{ i+1 }}</span>
                  <span class="accordion-title">{{ item.title }}</span>
                </span>
              </div>
              <div
                :id="`collapseOne${i}`"
                class="collapse"
                :class="{ 'show': i == 0 }"
                :aria-labelledby="`headingOne${i}`"
                data-parent="#accordionOne"
              >
                <div class="accordion-body">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-7">
          <h4>{{ $t('programme.why.title') }}</h4>
          <p>{{ $t('programme.why.description') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    accordionItems () {
      return [
        {
          title: this.$t('programme.tutorials.title'),
          description: this.$t('programme.tutorials.description')
        },
        {
          title: this.$t('programme.seminars.title'),
          description: this.$t('programme.seminars.description')
        },
        {
          title: this.$t('programme.meetings.title'),
          description: this.$t('programme.meetings.description')
        }
      ]
    }
  }
})
</script>

<style lang="scss" scoped>
.accordion {
  h4 {
    margin-bottom: .5rem;
  }
}

.h2-logo {
  height: 80px;
}
</style>
