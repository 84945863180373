
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    accordionItems () {
      return [
        {
          title: this.$t('programme.tutorials.title'),
          description: this.$t('programme.tutorials.description')
        },
        {
          title: this.$t('programme.seminars.title'),
          description: this.$t('programme.seminars.description')
        },
        {
          title: this.$t('programme.meetings.title'),
          description: this.$t('programme.meetings.description')
        }
      ]
    }
  }
})
