
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      contacts: [
        'jaro.varchola@komenskehocollege.sk',
        'michal.gacko@komenskehocollege.sk'
      ]
    }
  }
})
