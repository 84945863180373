<template>
  <div id="students" class="filter students kc-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2>{{ $t('students.title', { year: '2020/2021' }) }}</h2>
        </div>
      </div>
      <div class="row justify-content-center">
        <div v-for="(student, i) of students" :key="i" class="student col-lg-3 col-6">
          <div class="image-wrapper" :class="{ '--empty': !student.image }">
            <img v-if="student.image" :src="'gal/' + student.image" :alt="student.name" />
            <i v-else class="fa fa-user-graduate"></i>
          </div>
          <h3 class="p-large kc-color-primary font-weight-bold">{{ student.name }}</h3>
          <p v-if="student.text" class="text-center description">{{ student.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import studentsSk from './students-sk.json'
import studentsEn from './students-en.json'
import Person from '@/types/Person'

export default defineComponent({
  data () {
    return {
      students: [] as Array<Person>
    }
  },

  watch: {
    '$root.$i18n.locale': {
      handler (val) {
        if (val === 'en') {
          this.students = studentsEn
        }
        if (val === 'sk') {
          this.students = studentsSk
        }
      },
      immediate: true
    }
  }
})
</script>

<style lang="scss" scoped>
.student {
  margin-bottom: 1rem;
  padding: 0 1.5rem;

  h3 {
    padding: .5rem 1rem;
    text-align: center;
    margin-bottom: 0;
  }

  p {
    &.description {
      font-style: italic;
    }
  }
}

.image-wrapper {
  @media only screen and (min-width: 576px) and (max-width: 991px) {
    width: 50%;
    padding-top: 50%;
    left: 25%;
  }
}
</style>
