<template>
  <div class="spinner-wrapper">
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>

  <nav class="navbar navbar-expand-md navbar-custom fixed-top" :class="{ 'navbar-dark': !isHome }">
    <router-link class="navbar-brand" to="/">
      <img src="images/logo2.png" alt="alternative">
    </router-link>

    <button
      class="navbar-toggler" type="button" ref="collapseButton"
      data-toggle="collapse" data-target="#navbarsExampleDefault"
      aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon">
        <i class="fas fa-bars"></i>
      </span>
    </button>

    <div v-if="isHome" class="collapse navbar-collapse" id="navbarsExampleDefault" ref="collapse">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          >
            {{ $i18n.locale }}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a
              v-for="(locale, i) of $i18n.availableLocales" :key="i"
              class="dropdown-item"
              @click="changeLocale(locale)"
            >
              {{ locale }}
            </a>
          </div>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link page-scroll" href="#registration">{{ $t('menu.registration') }}</a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" href="#" @click.prevent="scrollToSection('content')">{{ $t('menu.program') }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" @click.prevent="scrollToSection('teachers')">{{ $t('menu.tutors') }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" @click.prevent="scrollToSection('students')">{{ $t('menu.students') }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" @click.prevent="scrollToSection('academics')">{{ $t('menu.academics') }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" @click.prevent="scrollToSection('contact')">{{ $t('menu.contact') }}</a>
        </li>
      </ul>
    </div>
  </nav>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    isHome () {
      return this.$route.fullPath === '/'
    }
  },

  // created () {
  //   if (window.navigator.language !== 'sk-SK') {
  //     this.changeLocale('en')
  //   }
  // },

  methods: {
    changeLocale (locale: string) {
      this.$i18n.locale = locale
    },

    scrollToSection(id: string) {
      const section = document.getElementById(id)

      if (!section) {
        return
      }

      window.scrollTo({
        top: section.offsetTop - 60,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.navbar-brand {
  img {
    max-width: 100px;
  }
}

.navbar-dark {
  background-color: var(--kc-color-primary);

  .navbar-nav .nav-link {
    color: var(--kc-color-white);
  }
}

.navbar-toggler-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-item {
  text-transform: uppercase;

  .dropdown-menu {
    min-width: initial;
  }

  .dropdown-item {
    cursor: pointer;

    &:hover {
      background-color: transparent;
      color: var(--kc-color-secondary);
    }
  }
}

.spinner-wrapper {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #112E50;
}

.spinner {
  position: absolute;
  top: 50%; /* centers the loading animation vertically one the screen */
  left: 50%; /* centers the loading animation horizontally one the screen */
  width: 3.75rem;
  height: 1.25rem;
  margin: -0.625rem 0 0 -1.875rem; /* is width and height divided by two */
  text-align: center;

  > div {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background-color: #fff;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0); }
  40% { -webkit-transform: scale(1.0); }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    -ms-transform: scale(1.0);
    transform: scale(1.0);
  }
}
</style>
