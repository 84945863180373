<template>
   <div id="contact" class="form-2 kc-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 text-sm-left text-center">
          <div class="text-container">
            <h2>{{ $t('contact.title') }}</h2>

            <ul class="list-unstyled li-space-lg">
              <li v-for="(contact, i) of contacts" :key="i">
                <i class="fas fa-envelope"></i>
                <a :href="`mailto:${contact}`" target="_blank" class="fw-bold">{{ contact }}</a>
              </li>
            </ul>
          </div>
        </div>

        <!-- <div class="col-lg-6">
          <img src="images/amcham.png" alt="alternative" />
          <p>Partnerom programu je tiež <b>Americká obchodná komora</b></p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      contacts: [
        'jaro.varchola@komenskehocollege.sk',
        'michal.gacko@komenskehocollege.sk'
      ]
    }
  }
})
</script>

<style lang="scss" scoped>
a {
  font-weight: bold;
}

#contact {
  background-color: #fbfbfb;
}
</style>
