
import { defineComponent, PropType } from 'vue'
import Person from '@/types/Person'
type PersonState = Person & { _clamped: boolean }

export default defineComponent({
  props: {
    profiles: {
      type: Array as PropType<Array<Person>>,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      profilesData: [] as Array<PersonState>
    }
  },

  watch: {
    profiles: {
      handler: function(newValue: Array<Person>) {
        this.profilesData = newValue.map(profile => ({ ...profile, _clamped: true }))
      },
      immediate: true
    }
  },

  methods: {
    toogleClamp(i: number) {
      this.profilesData[i]._clamped = !this.profilesData[i]._clamped
    }
  }
})
