import { createI18n } from 'vue-i18n'
import en from '@/assets/lang/en.json'
import sk from '@/assets/lang/sk.json'

export const locale = 'sk'
export const languages = {
  en,
  sk
}

export default createI18n({
  locale,
  messages: Object.assign(languages)
})
