<template>
  <header id="header" class="header">
    <div class="header-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-container">
              <h1>KOMENSKÉHO COLLEGE</h1>
              <p class="p-heading p-large" v-html="$t('header.subtitle')"></p>
              <!-- <a class="btn-solid-lg page-scroll" href="#registration">
                PRIHLÁS SA
              </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url('/images/pozadie.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  .header-content {
    padding-top: 8rem;
    padding-bottom: 1rem;
    text-align: center;
    width: 100%;
  }

  .text-container {
    margin-bottom: 3rem;
  }

  h1 {
    margin-bottom: 0.5rem;
    color: var(--kc-color-white);
  }

  .p-large {
    margin-bottom: 2rem;
    color: #dfe5ec;
  }

  .btn-solid-lg {
    margin-right: 0.5rem;
    margin-bottom: 1.25rem;
  }

  @media (min-width: 768px) {
    .header-content {
      padding-top: 7rem;
      padding-bottom: 2.5rem;
    }

    h1 {
      font: 700 3.5rem/4rem "Montserrat", sans-serif;
    }
  }

  @media (min-width: 992px) {
    .header-content {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }

  @media (min-width: 1200px) {
    .header-content {
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
    }
  }
}
</style>
