
import { defineComponent } from 'vue'
import studentsSk from './students-sk.json'
import studentsEn from './students-en.json'
import Person from '@/types/Person'

export default defineComponent({
  data () {
    return {
      students: [] as Array<Person>
    }
  },

  watch: {
    '$root.$i18n.locale': {
      handler (val) {
        if (val === 'en') {
          this.students = studentsEn
        }
        if (val === 'sk') {
          this.students = studentsSk
        }
      },
      immediate: true
    }
  }
})
