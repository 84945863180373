
import { defineComponent }  from 'vue'
import About from '../components/About.vue'
import AppHeader            from '../components/AppHeader.vue'
import Contact              from '../components/Contact.vue'
import Program              from '../components/Program.vue'
import Students             from '../components/students/Students.vue'
import Profiles             from '../components/profiles/Profiles.vue'
import Person               from '@/types/Person'

import teachersSk           from '../assets/data/teachers-sk.json'
import teachersEn           from '../assets/data/teachers-en.json'
import academicsSk          from '../assets/data/academics-sk.json'
import academicsEn          from '../assets/data/academics-en.json'

export default defineComponent({
  name: 'Home',

  components: {
    About,
    AppHeader,
    Contact,
    Profiles,
    Program,
    Students
  },

  data() {
    return {
      teachers: [] as Array<Person>,
      academics: [] as Array<Person>
    }
  },

  watch: {
    '$root.$i18n.locale': {
      handler(val) {
        if (val === 'en') {
          this.teachers = teachersEn
          this.academics = academicsEn
        }
        if (val === 'sk') {
          this.teachers = teachersSk
          this.academics = academicsSk
        }
      },
      immediate: true
    }
  }
})
