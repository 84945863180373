
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    isHome () {
      return this.$route.fullPath === '/'
    }
  },

  // created () {
  //   if (window.navigator.language !== 'sk-SK') {
  //     this.changeLocale('en')
  //   }
  // },

  methods: {
    changeLocale (locale: string) {
      this.$i18n.locale = locale
    },

    scrollToSection(id: string) {
      const section = document.getElementById(id)

      if (!section) {
        return
      }

      window.scrollTo({
        top: section.offsetTop - 60,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
})
