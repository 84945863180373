<template>
  <div id="registration" class="kc-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mb-3">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              src="https://www.youtube.com/embed/EDixS2OSC2Y"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="text-container ml-lg-3 text-sm-left text-center">
            <h2>{{ $t('Prihlasovanie') }}</h2>
            <p>
              Prihlasovanie je otvorené pre študentov všetkých fakúlt Univerzity Komenského v 2., 3. alebo 4. ročníku štúdia. Prijatých študentov čaká ročný program s osobným prístupom a s dôrazom na rozvoj myslenia a modernú filozofiu.
            </p>
            <p>
              Do emailu okrem mena, fakulty a ročníka štúdia napíšte aj krátke odpovede na tieto tri otázky:
              <ol>
                <li>Prečo by mali ľudia študovať váš odbor?</li>
                <li>Prečo ste sa prihlásili do programu College?</li>
                <li>Aké myšlienky vás za posledný rok štúdia alebo čítania najviac ovplyvnili?</li>
              </ol>
            </p>
            <hr />
            <p class="testimonial-text">
              Prihlasovacie emaily posielajte na:
            </p>
            <div class="testimonial-author">
              <a href="mailto:michal.gacko@komenskehocollege.sk" target="_blank">
                <i class="fas fa-envelope"></i> michal.gacko@komenskehocollege.sk
              </a>
            </div>
            <!-- <p>
              Prihlasovanie <s>sa končí <b>1. októbra o polnoci.</b></s> je predĺžené do <b>polnoci 15. októbra</b>
            </p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.testimonial-text {
  margin-bottom: .5rem;
}
.testimonial-author {
  margin-bottom: 1rem;
}
.fa-envelope {
  color: #FCB415;
}
s {
  opacity: .25;
}
</style>
