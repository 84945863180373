<template>
  <div :id="id" class="basic-2 kc-section">
    <div class="container">
      <h2>{{ title }}</h2>
      <div class="row justify-content-center">
        <div v-for="(profile, i) of profilesData" :key="i" class="d-flex justify-content-center col-sm-6">
          <div class="profile">
            <div class="image-wrapper">
              <img class="_img" :src="'images/' + profile.image" alt="alternative" />
            </div>
            <h3 class="p-large kc-color-primary font-weight-bold">{{ profile.name }}</h3>
            <div class="description" :class="{ 'clamped': profile._clamped }">
              <p class="testimonial-text -indented text-justify">
                {{ profile.text }}
              </p>
              <a href="#" @click.prevent="toogleClamp(i)">
                {{ profile._clamped ? $t('Zobraziť viac') : $t('Skryť') }}
                <img class="arrow" src="/images/up-arrow.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import Person from '@/types/Person'
type PersonState = Person & { _clamped: boolean }

export default defineComponent({
  props: {
    profiles: {
      type: Array as PropType<Array<Person>>,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      profilesData: [] as Array<PersonState>
    }
  },

  watch: {
    profiles: {
      handler: function(newValue: Array<Person>) {
        this.profilesData = newValue.map(profile => ({ ...profile, _clamped: true }))
      },
      immediate: true
    }
  },

  methods: {
    toogleClamp(i: number) {
      this.profilesData[i]._clamped = !this.profilesData[i]._clamped
    }
  }
})
</script>

<style lang="scss" scoped>
h2 {
  margin-bottom: 3.25rem;
}

h3 {
  min-height: 52px;
}

.profile {
  margin-bottom: 2rem;
  padding: 2rem 1rem;
  border-radius: 1rem;
  transition: all .6s ease;

  @media only screen and (min-width: 1200px) {
    max-width: 80%;
  }

  &:hover {
    box-shadow: -12px -12px 20px 0px rgba(var(--kc-color-primary-rgb), .2);
    transition-delay: .3s;
    transform: translateY(-10px);
    // transform: perspective(1000px) rotateX(-1deg) rotateY(1deg);

    ._img {
      transition-delay: .3s;
      -moz-transform: scale(1.15);
      -webkit-transform: scale(1.15);
      transform: scale(1.15);
    }
  }
}

.image-wrapper {
  width: 62.5%;
  padding-top: 62.5%;
  left: 18.75%;
}

.description {
  margin: 0 auto 1rem;
  padding: 0 1rem;

  .arrow {
    height: .875rem;
    margin-left: .5rem;
    transform: rotate(0deg);
  }

  a {
    display: inline-flex;
    align-items: center;
    padding: .5rem 1rem;
    border-radius: 2rem;
    color: var(--kc-color-white);
    background-color: var(--kc-color-secondary);
    font-size: .875rem;
    text-decoration: none;

    &:hover {
      color: var(--kc-color-white, #fff);
    }
  }

  &.clamped {
    .arrow {
      transform: rotate(180deg);
    }

    p {
      height: 75px;
      position: relative;
      overflow: hidden;

      &::after {
        content: "";
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 30%;
        height: 25px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
      }
    }
  }
}
</style>
