<template>
  <div class="container">
    <div class="row">
      <div class="mt-5 text-center">
        <p>Takúto stránku sme zatiaľ neurobili alebo žeby sa stala chyba?</p>
        <p>&#129300;</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PageNotFound'
})
</script>

<style lang="scss" scoped>
.container {
  margin-top: 80px;
}

p {
  font-size: 2rem;
}
</style>
